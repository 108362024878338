import { gql } from '@apollo/client';

export const shippingHandlingInfoQuery = gql`
  query FetchShippingHandlingInfo {
    shippingHandlingInfo {
      btnShippingHandling {...values}
      txtDeliveryDate {...values}
      txtInfoFooter {...values}
      txtInfoLeftTab {...values}
      txtInfoRightTab {...values}
      txtInfoRightTab {...values}
      txtNoShipping {...values}
    }
  }

  fragment values on TextPair {
    key
    value
  }
`;

export default {
  shippingHandlingInfoQuery,
};
