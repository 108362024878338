import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import UserState from '../userState/UserState';
import RewardsPromos from '../RewardsPromos/RewardsPromos';
import RewardsPromosV2 from '../RewardsPromos/RewardsPromosV2';
import MarketingSpot from '../MarketingSpot/MarketingSpot';
import SwitchTestContext from '../../../context/SwitchTestContext';
import { useIsL } from '../../../hooks/useBreakPoint';

function UserSectionDetails({
  purchaseContext,
  setPurchaseContext,
  setShowDiscount,
  showNotification,
  setShowNotification,
  page,
}) {
  const bagItemCount = purchaseContext?.bagItems?.items && Object.keys(purchaseContext?.bagItems?.items).length !== 0 ? purchaseContext?.bagItems?.items?.length : '0';
  const { userData, textFor, rewardsAndPromotions } = purchaseContext;
  const {
    digitalData,
  } = useContext(SwitchTestContext);
  const hasBagMfeV2 = digitalData && digitalData['chk-has-bag-mfe-v2'];
  const RewardsPromosComponent = hasBagMfeV2 ? RewardsPromosV2 : RewardsPromos;
  const isLBreakPoint = useIsL();

  return (
    <div className="welcome-user-section">
      {userData
      && (
      <UserState
        userData={userData}
        tmntData={textFor}
        loyaltyEstimatedPoints={userData?.loyaltyInfo?.loyaltyEstimatedPoints || '0'}
        isEmptyBag={bagItemCount === '0'}
        totalCoupons={rewardsAndPromotions?.totalCoupons}
      />
      )}
      {userData?.isLoggedIn && userData?.hasLoyaltyEnabled
      && (!hasBagMfeV2 || (hasBagMfeV2 && !isLBreakPoint)) && (
        <>
          <hr className="sign-in-separator" />
          <RewardsPromosComponent
            page={page}
            purchaseContext={purchaseContext}
            setPurchaseContext={setPurchaseContext}
            setShowDiscount={setShowDiscount}
            showNotification={showNotification}
            setShowNotification={setShowNotification}
          />
          <MarketingSpot
            espotId={page === 'checkout' ? 'checkout_memberpricingleft' : 'bag_memberpricing_belowrewards'}
            espotData={purchaseContext?.eSpots?.cartMemberPricingBelowRewards}
          />
        </>
      )}
    </div>
  );
}

UserSectionDetails.defaultProps = {
  page: '',
  purchaseContext: {},
  showNotification: false,
};

UserSectionDetails.propTypes = {
  page: PropTypes.string,
  purchaseContext: PropTypes.instanceOf(Object),
  setPurchaseContext: PropTypes.func.isRequired,
  setShowDiscount: PropTypes.func.isRequired,
  showNotification: PropTypes.bool,
  setShowNotification: PropTypes.func.isRequired,
};

export default UserSectionDetails;
