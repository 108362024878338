import React, { useState, useEffect, useRef } from 'react';
import { ErrorMessage } from 'anf-core-react';
import $window from '../../../tools/window';
import $document from '../../../tools/document';

export default function PageHeaderError() {
  const [errorMessage, setErrorMessage] = useState('');
  const errorRef = useRef(null);

  useEffect(() => {
    const handleErrorEvent = (event) => {
      setErrorMessage(event.detail);
    };

    const handleErrorClearEvent = () => {
      setErrorMessage('');
    };

    $window.addEventListener('mfe:submit:error', handleErrorEvent);
    $window.addEventListener('mfe:applepay:errorClear', handleErrorClearEvent);

    return () => {
      $window.removeEventListener('mfe:submit:error', handleErrorEvent);
      $window.removeEventListener('mfe:applepay:errorClear', handleErrorClearEvent);
    };
  }, []);

  useEffect(() => {
    const errorMessageContainer = errorRef.current;
    if (errorMessage && errorMessageContainer) {
      const containerTop = errorMessageContainer.getBoundingClientRect().top + window.scrollY;
      const scrollTop = $window.pageYOffset || $document.documentElement.scrollTop;
      const offset = 100;
      if (containerTop - scrollTop <= offset) {
        $window.scrollTo({
          top: containerTop - offset,
          behavior: 'smooth',
        });
      }
    }
  }, [errorMessage]);

  return (
    <div className="error-message-banner-container" data-testid="error-message-banner" ref={errorRef}>
      {errorMessage && (
        <ErrorMessage id="express-payment-error">
          {errorMessage}
        </ErrorMessage>
      )}
    </div>
  );
}
