import { gql } from '@apollo/client';

export const selectedStoreEdd = gql`
  query FetchSelectedStoreEDD {
    selectedStoreEdd {
      edd
    }
  }
`;

export default {
  selectedStoreEdd,
};
