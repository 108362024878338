import React from 'react';
import PropTypes from 'prop-types';
import {
  Modal,
} from 'anf-core-react';
import Tmnt from '../../Tmnt/Tmnt';

const defaultProps = {
  txtInfoNoShipping: undefined,
  txtInfoFooter: undefined,
  txtInfoRightTab: undefined,
};

const propTypes = {
  onClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  txtInfoLeftTab: PropTypes.instanceOf(Object).isRequired,
  txtInfoRightTab: PropTypes.instanceOf(Object),
  txtInfoFooter: PropTypes.instanceOf(Object),
  txtHeading: PropTypes.instanceOf(Object).isRequired,
  txtInfoNoShipping: PropTypes.instanceOf(Object),
};

export default function ShippingHandlingInfoModal(props) {
  const {
    onClose,
    isOpen,
    txtInfoLeftTab,
    txtInfoRightTab,
    txtHeading,
    txtInfoFooter,
    txtInfoNoShipping,
  } = props;

  return (
    <Modal
      id="shipping-handling-modal"
      isOpen={isOpen}
      heading={(
        <h2 className="h2" data-testid="shipping-handling-modal-header">
          <Tmnt tmnt={txtHeading} />
        </h2>
      )}
      onClose={onClose}
    >
      <div className="shipping-and-handling-modal-wrapper">
        <div
          className="shippingAndHandling-body"
        >
          <div className="shippingAndHandling__rate-table-tab">
            <Tmnt tmnt={txtInfoLeftTab} isHtml />
          </div>
          {txtInfoRightTab && (
            <div
              className="shippingAndHandling__additional-info-tab"
              data-testid="shipping-handling-modal-additional-info"
            >
              <Tmnt tmnt={txtInfoRightTab} isHtml />
            </div>
          )}
        </div>
        {(!!txtInfoFooter || !!txtInfoNoShipping) && (
          <div className="shippingAndHandling-footer">
            {txtInfoFooter && (
              <div
                className="module-section__footer-content"
                data-testid="shipping-handling-modal-footer"
              >
                <Tmnt tmnt={txtInfoFooter} isHtml />
              </div>
            )}
            {txtInfoNoShipping && (
              <div
                className="noShippingUSContent"
                data-testid="shipping-handling-modal-noShippingUSContent"
              >
                <Tmnt tmnt={txtInfoNoShipping} isHtml />
              </div>
            )}
          </div>
        )}
      </div>
    </Modal>
  );
}
ShippingHandlingInfoModal.propTypes = propTypes;
ShippingHandlingInfoModal.defaultProps = defaultProps;
