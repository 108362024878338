import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'anf-core-react';
import Tmnt from '../../../../Tmnt/Tmnt';

export default function PudosPickUpInstructions({ tmntData }) {
  return (
    <section className="how-it-works" data-testid="pickup-instructions">
      {tmntData?.altPickupOcnHeading && (
      <div className="heading-banner">
        <h3>
          <Tmnt tmnt={tmntData?.altPickupOcnHeading} />
        </h3>
      </div>
      )}
      {tmntData?.altPickupOcnEmail && (
      <div className="icon-block-wrapper" data-testid="alt-pickup-email-block">
        <Icon
          icon="mail-anf"
          size="xs"
        />
        <div className="icon-text">
          <p><Tmnt tmnt={tmntData?.altPickupOcnEmail} /></p>
        </div>
      </div>
      )}

      {tmntData?.altPickupOcnID && (
      <div className="icon-block-wrapper" data-testid="alt-pickup-id-block">
        <Icon
          icon="id-anf"
          size="xs"
        />
        <div className="icon-text">
          <p><Tmnt tmnt={tmntData?.altPickupOcnID} /></p>
        </div>
      </div>
      )}

      {tmntData?.altPickupOcnClock && (
      <div className="icon-block-wrapper" data-testid="alt-pickup-clock-block">
        <Icon
          icon="clock"
          size="xs"
        />
        <div className="icon-text">
          <p><Tmnt tmnt={tmntData?.altPickupOcnClock} /></p>
        </div>
      </div>
      )}
    </section>
  );
}

PudosPickUpInstructions.defaultProps = {
  tmntData: {},
};
PudosPickUpInstructions.propTypes = {
  tmntData: PropTypes.instanceOf(Object),
};
