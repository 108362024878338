import {
  gql,
} from '@apollo/client';

const PAYMENT_STATUS_QUERY = gql`
  query GetPaymentStatus($paymentStatusURL: String!, $orderId: String!) {
    paymentStatus(paymentStatusURL: $paymentStatusURL, orderId: $orderId) {
      responseCode
      orderId
      statusCode
      paymentStatus
      statusMessages {
        code
        key
        message
      }
      success
    }
  }
`;

export default PAYMENT_STATUS_QUERY;
