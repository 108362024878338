import React, {
  useContext, useEffect, useState,
} from 'react';
import PropTypes from 'prop-types';
import PayOverTime from '../Common/PayOverTime/PayOverTime';
import CheckoutPageContext from '../../context/CheckoutPageContext';
import OrderTotals from '../Common/OrderTotals/OrderTotals';
import Promotions from '../Common/Promotions/Promotions';
import MarketingSpot from '../Common/MarketingSpot/MarketingSpot';
import BagList from '../Common/BagList/BagList';
import ExpressCheckoutWrapper from '../Common/ExpressCheckout/ExpressCheckoutWrapper';
import { useIsL } from '../../hooks/useBreakPoint';
import Tmnt from '../Tmnt/Tmnt';
import $window from '../../tools/window';
import SwitchTestContext from '../../context/SwitchTestContext';
import RewardsPromosRightRailV2 from '../Common/RewardsPromos/RewardsPromosRightRailV2';

export default function CheckoutRightRail({
  parentClass,
  showPayOverTime,
}) {
  const {
    digitalData,
  } = useContext(SwitchTestContext);

  const [mounted, setMounted] = useState(false);
  const isLBreakPoint = useIsL();

  const {
    checkoutPageState,
    setCheckoutPageState,
    setShowDiscount,
    showDiscount,
    showNotification,
    setShowNotification,
    updateCheckoutPageState,
  } = useContext(CheckoutPageContext);

  const hasItemsInBag = checkoutPageState?.bagItems?.items
    && Object.keys(checkoutPageState?.bagItems?.items).length !== 0;
  const { textFor, userData, rewardsAndPromotions } = checkoutPageState;
  const isLoggedIn = userData?.isLoggedIn;

  const isPAYPALPage = () => $window.location.href?.includes('/ProcessPayPalReturnAndOrderExpress');
  const [showExpressCheckout, setShowExpressCheckout] = useState(false);
  const useRecognizeCustomer = digitalData && digitalData['chk-mfe-recognize-customer']; // LD switch for Recognize customer
  const hasBagMfeV2 = digitalData && digitalData['chk-has-bag-mfe-v2'];

  useEffect(() => {
    setMounted(true);
  }, []);

  useEffect(() => {
    function updateExpressCheckoutVisibility() {
      const guestCustomerMailId = sessionStorage?.getItem('guestCustomerMailId');
      setShowExpressCheckout(!isLoggedIn && !guestCustomerMailId && !isPAYPALPage());
    }

    // Initial call to set the state based on current session storage values
    updateExpressCheckoutVisibility();

    // also run when event is triggered
    window.addEventListener('recognizeCustomer:persist:email', updateExpressCheckoutVisibility);

    return () => {
      window.removeEventListener('recognizeCustomer:persist:email', updateExpressCheckoutVisibility);
    };
  }, [isLoggedIn]);

  const getSelectedShipInfo = (shippingSpeed) => {
    const selectedShipInfo = shippingSpeed?.filter((d) => d?.isSelected);
    return selectedShipInfo?.length ? selectedShipInfo[0] : {};
  };

  if (!mounted) { return null; }

  return (
    <div className={`checkout-mfe-web-service ${parentClass}`}>
      {!isLBreakPoint && checkoutPageState?.textFor && (
        <>
          {useRecognizeCustomer && (
            <div className="express-checkout">
              {(showExpressCheckout) && (
                <h1>
                  <span>
                    <Tmnt tmnt={checkoutPageState?.textFor?.expressCheckout} />
                  </span>
                </h1>
              )}
              {showExpressCheckout && (
                <ExpressCheckoutWrapper
                  pageContext={checkoutPageState}
                  updatePageState={updateCheckoutPageState}
                  isDisabled={!hasItemsInBag}
                  page="checkout"
                />
              )}
              {(showExpressCheckout
                && (checkoutPageState?.applePayButton?.hasApplePay
                  || checkoutPageState?.payPalExpressButton?.hasPayPal))
                && (
                  <span
                    className="horizontal-separator"
                  />
                )}
            </div>
          )}
          <h2 className="header-bag-text">
            <Tmnt tmnt={checkoutPageState?.textFor?.yourBag} />
          </h2>
          <div data-testid="baglist" className="checkout-baglist">
            <BagList
              variant="rightRail"
              bagItems={checkoutPageState?.bagItems?.items}
              tmntData={checkoutPageState?.textFor}
              shippingInfo={getSelectedShipInfo(checkoutPageState?.shippingSpeed)}
            />
          </div>
        </>
      )}
      {isLoggedIn && userData?.hasLoyaltyEnabled && isLBreakPoint && hasBagMfeV2 && (
        <RewardsPromosRightRailV2
          coupons={rewardsAndPromotions?.couponInfo}
          earnPointsTMNT={userData?.loyaltyInfo?.earnPointsTMNT}
          estimatedPointsTMNT={userData?.loyaltyInfo?.estimatedPointsTMNT}
          loyaltyEstimatedPoints={userData?.loyaltyInfo?.loyaltyEstimatedPoints || '0'}
          loyaltyStatus={userData?.loyaltyInfo?.accountStatus}
          totalCoupons={rewardsAndPromotions?.totalCoupons}
          legalTermsTmntKey={rewardsAndPromotions?.legalTermsTmntKey}
          tmntData={textFor}
          page={checkoutPageState?.page}
          purchaseContext={checkoutPageState}
          setPurchaseContext={setCheckoutPageState}
          setShowDiscount={setShowDiscount}
          showNotification={showNotification}
          setShowNotification={setShowNotification}
          notification={userData?.userNotifications}
        />
      )}

      {checkoutPageState?.textFor && (
        <>
          <Promotions
            className="checkoutpage-promo"
            purchaseContext={checkoutPageState}
            setPurchaseContext={setCheckoutPageState}
            tmntData={checkoutPageState?.textFor}
            accordionVariant="bordered"
            showDiscount={showDiscount}
            setShowDiscount={setShowDiscount}
            showNotification={showNotification}
            setShowNotification={setShowNotification}
            page={checkoutPageState?.page}
          />
          <MarketingSpot
            espotId="checkout_memberpricing_belowpromotions"
            espotData={checkoutPageState?.eSpots?.checkoutMemberPricingBelowPromotions}
          />
        </>
      )}
      <OrderTotals
        cartTotalAmountFmt={checkoutPageState?.orderTotals?.cartTotalAmountFmt}
        tmntData={checkoutPageState?.textFor}
        promoAmountFmt={checkoutPageState?.orderTotals?.promoAmountFmt}
        cartPayableAmount={checkoutPageState?.orderTotals?.grandTotalFmt}
        includesConsumptionTax={
          checkoutPageState?.orderTotals?.includesConsumptionTax
        }
        charityRoundUpFmt={checkoutPageState?.orderTotals?.charityTotalFmt}
        includesGst={checkoutPageState?.orderTotals?.includesGst}
        includesVat={checkoutPageState?.orderTotals?.includesVat}
        showDisclosure={!!checkoutPageState?.config?.showDisclosure}
        isVatIncluded={checkoutPageState?.config?.isVatIncluded}
        totalItemsInBag={checkoutPageState?.orderTotals?.totalItemsInBag}
        shippingHandlingChargeFmt={checkoutPageState?.orderTotals?.shippingHandlingChargeFmt}
        estimatedTaxAmountFmt={checkoutPageState?.orderTotals?.estimatedTaxAmountFmt}
        isCheckoutPage
        shippingModeType={checkoutPageState?.shipMethod?.shipModeType}
        totalGiftBoxesAmount={checkoutPageState?.orderTotals?.totalGiftBoxesAmount}
        totalGiftBoxes={checkoutPageState?.orderTotals?.totalGiftBoxes}
        giftCards={checkoutPageState?.giftCards?.appliedGiftCards}
        displayFreeShippingText={checkoutPageState?.orderTotals?.displayFreeShippingText}
        displayGiftReceiptText={checkoutPageState?.orderTotals?.displayGiftReceiptText}
        vatDetails={checkoutPageState?.vatDetails}
      />
      {showPayOverTime && checkoutPageState?.switches?.showPayOverTime && (
        <div data-testid="pay-overtime-check-rr" className="scope-1892">
          <PayOverTime
            page="checkout"
            inline
            purchaseAmount={checkoutPageState?.orderTotals?.subTotal}
            hasGiftCard={checkoutPageState?.bagItems?.hasGiftCard}
          />
        </div>
      )}
    </div>
  );
}

CheckoutRightRail.defaultProps = {
  parentClass: 'checkout-right-rail',
  showPayOverTime: true,
};

CheckoutRightRail.propTypes = {
  parentClass: PropTypes.string,
  showPayOverTime: PropTypes.bool,
};
