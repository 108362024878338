import React, {
  useState,
  useCallback,
  useMemo,
  useContext,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  InputField,
  Icon,
  SingleEntryForm,
  HorizontalSeparator,
} from 'anf-core-react';
import { useIsS } from '../../../hooks/useBreakPoint';
import CheckoutPageContext from '../../../context/CheckoutPageContext';
import $window from '../../../tools/window';
import { parseJSONObject } from '../../../tools/utils';
import trackAction from '../../../tools/analytics';
import CheckoutCountrySelector from '../../Common/CountrySelector/CountrySelector';

const textProp = PropTypes.shape({
  key: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
});

const propTypes = {
  defaultSearchValue: PropTypes.string,
  isInputSearching: PropTypes.bool.isRequired,
  isNearMeSearching: PropTypes.bool.isRequired,
  isGeolocationBlocked: PropTypes.bool.isRequired,
  onInputSearch: PropTypes.func.isRequired,
  onNearMeSearch: PropTypes.func.isRequired,
  text: PropTypes.shape({
    cityStateZip: textProp,
    nearMe: textProp,
    or: textProp,
    findPickupStore: textProp,
  }),
};

const defaultProps = {
  defaultSearchValue: undefined,
  text: undefined,
};

/**
 * PickupSearch component provides a search interface for finding pickup locations or stores.
 * It includes both a text input for location search and a "Near Me" button for
 * geolocation-based search.
 *
 * @component
 * @param {Object} props - Component props
 * @param {string} [props.defaultSearchValue] - Initial value for the search input
 * @param {boolean} props.isInputSearching - Flag indicating if a text-based search is in progress
 * @param {boolean} props.isNearMeSearching - Flag indicating if a geolocation search is in progress
 * @param {boolean} props.isGeolocationBlocked - Flag indicating if geolocation access is blocked
 * @param {Function} props.onInputSearch - Callback function for handling text-based searches
 * @param {Function} props.onNearMeSearch - Callback function for handling geolocation searches
 * @param {Object} [props.text] - Text content for the component
 * @param {Object} [props.text.cityStateZip] - Text for the search input placeholder
 * @param {Object} [props.text.nearMe] - Text for the "Near Me" button
 * @param {Object} [props.text.or] - Text for the separator between search options
 * @param {Object} [props.text.findPickupStore] - Text for the component title
 * @returns {React.ReactElement|null} The rendered component or null if text prop is not provided
 */

export default function PickupSearch({
  defaultSearchValue,
  isInputSearching,
  isNearMeSearching,
  isGeolocationBlocked,
  onInputSearch,
  onNearMeSearch,
  text,
}) {
  const [searchInputValue, setSearchInputValue] = useState(defaultSearchValue);
  const isMobileView = useIsS();
  const [updatedCountry, setUpdatedCountry] = useState('');
  const {
    checkoutPageState,
  } = useContext(CheckoutPageContext);

  const handleInputChange = useCallback((inputValue) => {
    setSearchInputValue(inputValue);
  }, []);

  useEffect(() => {
    if (checkoutPageState?.shippingAddress?.country && updatedCountry === '') {
      setUpdatedCountry(checkoutPageState.shippingAddress?.country);
    }
  }, [checkoutPageState.shippingAddress, updatedCountry]);

  const inputSearch = useCallback(async (e) => {
    e.preventDefault();
    if (!searchInputValue) return;
    await onInputSearch({
      searchInputValue,
      country: updatedCountry,
    });
    trackAction('analytics.checkoutInteraction', {
      type: checkoutPageState?.shipMethod?.shipModeType,
      message: 'find locations - submit',
    });
  }, [onInputSearch, searchInputValue, updatedCountry,
    checkoutPageState?.shipMethod?.shipModeType]);

  const nearMeSearch = useCallback(async (e) => {
    e.preventDefault();
    await onNearMeSearch();
  }, [onNearMeSearch]);

  const handleEnterKeyPress = useCallback((e) => {
    if (e.key === 'Enter') {
      inputSearch(e);
    }
  }, [inputSearch]);

  const searchInput = useMemo(() => {
    if (text) {
      return (
        <div className="pickup-search-input">
          <SingleEntryForm>
            <InputField
              autoComplete="off"
              id="pickup-point-search"
              label={text?.cityStateZip ? text.cityStateZip.value : ''}
              isInvalid={false}
              onChange={(e) => handleInputChange(e.target.value)}
              onKeyDown={handleEnterKeyPress}
              isRequired
              value={searchInputValue}
            >
              <Button
                classList="pickup-search-button"
                variant="secondary"
                onClick={(e) => inputSearch(e)}
                isDisabled={isInputSearching || isNearMeSearching}
                isProcessing={isInputSearching}
              >
                {!isInputSearching && (
                  <Icon
                    icon="search"
                    labelText="search"
                  />
                )}
              </Button>
            </InputField>
          </SingleEntryForm>
        </div>
      );
    }
    return (<div />);
  }, [
    text,
    searchInputValue,
    isInputSearching,
    isNearMeSearching,
    handleInputChange,
    inputSearch,
    handleEnterKeyPress,
  ]);

  const nearMe = useMemo(() => {
    if (text) {
      return (
        <div>
          <Button
            classList="nearme-button"
            variant="secondary"
            isFullWidth
            onClick={(e) => nearMeSearch(e)}
            isDisabled={isInputSearching || isNearMeSearching || isGeolocationBlocked}
            isProcessing={isNearMeSearching}
          >
            {!isNearMeSearching && (
              <Icon
                icon="location-anf"
                labelText="location"
              />
            )}
            <div
              className="nearme-button-text"
              data-testid="nearme-button-text"
            >
              {text?.nearMe.value}
            </div>
          </Button>
        </div>
      );
    }
    return (<div />);
  }, [text, isGeolocationBlocked, isInputSearching, isNearMeSearching, nearMeSearch]);

  const orSeparator = useMemo(() => {
    if (text) {
      return (
        <div className="horizontal-separator-wrapper">
          <HorizontalSeparator>
            <span>{text?.or.value}</span>
          </HorizontalSeparator>
        </div>
      );
    }
    return (<div />);
  }, [text]);

  if (!text) return null;

  if (checkoutPageState?.shipMethod?.shipModeType === 'Popins') {
    const localStorageKey = 'preferredStore';
    const localStorageValue = $window?.localStorage?.getItem(localStorageKey);
    const item = parseJSONObject(localStorageValue);
    if (item.storeNumber) {
      return null;
    }
  } else if (checkoutPageState?.shipMethod?.shipModeType === 'Pudos') {
    const sessionStorageKey = 'pudosCheckoutData';
    const sessionStorageValue = $window?.sessionStorage?.getItem(sessionStorageKey);
    const item = parseJSONObject(sessionStorageValue);
    if (item?.selectedStore) {
      return null;
    }
  }
  const onUpdateCountryName = (value) => {
    setUpdatedCountry(value);
    setSearchInputValue('');
  };
  return (
    <div id="pickup-search-container" className="pickup-search-wrapper" data-testid="pickup-search-container">
      <h2 className="pickup-search-title">{text.findPickupStore.value}</h2>
      {checkoutPageState?.shipOrPickup?.countryList?.length > 0
      && checkoutPageState?.shipMethod?.shipModeType === 'Popins' && (
        <div className="pickup-search-country">
          <CheckoutCountrySelector
            countryList={checkoutPageState?.shipOrPickup?.countryList}
            country={checkoutPageState?.shipOrPickup?.country}
            text={text}
            onUpdateCountry={onUpdateCountryName}
          />
        </div>
      )}
      {isMobileView ? (
        <>
          {searchInput}
          {orSeparator}
          {nearMe}
        </>
      ) : (
        <div className="vertical-separator">
          {nearMe}
          {searchInput}
        </div>
      )}
    </div>
  );
}

PickupSearch.propTypes = propTypes;
PickupSearch.defaultProps = defaultProps;
