import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import { shippingHandlingInfoQuery } from '../../../gql/shippingHandling.gql';
import ShippingHandlingInfoModal from './ShippingHandlingInfoModal';
import Tmnt from '../../Tmnt/Tmnt';

export default function ShippingHandlingContainer() {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const {
    loading: infoLoading,
    error: infoError,
    data,
  } = useQuery(shippingHandlingInfoQuery);

  const toggleHandlingModal = () => setIsModalVisible((isOpen) => !isOpen);

  if (infoLoading || infoError || !data) return null;

  return (
    <div className="shipping-handling-wrapper">
      <p className="delivery-estimate-label"><Tmnt tmnt={data.shippingHandlingInfo.txtDeliveryDate} /></p>
      <button className="button link-button" onClick={toggleHandlingModal} type="button" data-testid="btn-shipping-handling-modal">
        <Tmnt tmnt={data.shippingHandlingInfo.btnShippingHandling} />
      </button>
      <ShippingHandlingInfoModal
        isOpen={isModalVisible}
        onClose={toggleHandlingModal}
        txtHeading={data.shippingHandlingInfo.btnShippingHandling}
        txtInfoLeftTab={data.shippingHandlingInfo.txtInfoLeftTab}
        txtInfoRightTab={data.shippingHandlingInfo.txtInfoRightTab}
        txtInfoFooter={data.shippingHandlingInfo.txtInfoFooter}
        txtInfoNoShipping={data.shippingHandlingInfo.txtNoShipping}
      />
    </div>
  );
}
