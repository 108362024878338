import React, { useEffect, useRef, useState } from 'react';
import { Dialog } from 'anf-core-react';
import PropTypes from 'prop-types';
import { useLazyQuery } from '@apollo/client';
import PAYMENT_STATUS_QUERY from '../../../gql/paymentStatus.gql';
import useLog from '../../useLog/useLog';
import Tmnt from '../../Tmnt/Tmnt';
import { isValidPXPOrigin, isPaymentDeclined } from '../../../tools/creditCard';

export default function ThreeDsChallengeForm({
  threeDsInfo,
  threeDsMessage,
  closeButtonLabel,
  onSuccess,
  onFailure,
  onCancel,
}) {
  const logger = useLog('checkout.3DSChallengeForm');
  const [isFormOpen, setIsFormOpen] = useState(true);
  const threeDsIframe = useRef(null);

  const [getPaymentStatus] = useLazyQuery(PAYMENT_STATUS_QUERY, {
    fetchPolicy: 'no-cache',
    context: { batch: true },
    ssr: false,
    onCompleted: (res) => {
      if (
        res?.paymentStatus?.success
        && res?.paymentStatus?.paymentStatus === 'authorized'
      ) {
        logger.debug('PAYMENT_STATUS_QUERY RESULT', res);
        onSuccess();
      } else {
        onCancel();
      }
    },
    onError: (err) => {
      logger.error(`ERR: PAYMENT_STATUS_QUERY: ${JSON.stringify(err)}`);
      const errorMessage = err?.message ?? 'Something went wrong. Please try again.';
      onFailure(errorMessage);
    },
  });

  useEffect(() => {
    function iFrameMessageListener(event) {
      if (!event || !isValidPXPOrigin(event?.origin)) {
        return;
      }
      try {
        const eventData = (event?.data && typeof event?.data === 'string' && JSON.parse(event.data)) || event?.data;
        const pxpId = threeDsIframe.current.getAttribute('data-pxp-id');
        if (!eventData?.userInteractionRequired || !(eventData?.id === pxpId)) {
          setIsFormOpen(false);
        }
      } catch (e) {
        logger.error(`Error parsing iframe data: ${e?.message}`);
      }
    }
    window.addEventListener('message', iFrameMessageListener);
    return () => {
      window.removeEventListener('message', iFrameMessageListener);
    };
  }, [logger]);

  const onIframeReady = () => {
    try {
      // if we have access to the contents, data will be truthy
      const iframeData = threeDsIframe.current.contentWindow.document.body.textContent;
      if (iframeData && typeof iframeData === 'string') {
        const iframeDataObj = JSON.parse(iframeData);
        if (iframeDataObj.status && !isPaymentDeclined(iframeDataObj)) {
          onSuccess();
        } else {
          const errorMessage = iframeDataObj?.payments[0]?.errorMessage ?? 'Something went wrong. Please try again.';
          onFailure(errorMessage);
        }
        setIsFormOpen(false);
      }
    } catch (e) {
      logger.error(`Error reading iframe content: ${e?.message}`);
    }
  };

  const handleDialogClose = () => {
    setIsFormOpen(false);
    getPaymentStatus({
      variables: {
        paymentStatusURL: threeDsInfo?.paymentStatusURL,
        orderId: threeDsInfo?.orderId,
      },
    });
  };

  return (
    <Dialog
      heading={<Tmnt tmnt={threeDsMessage} />}
      isOpen={isFormOpen}
      id="three-ds-iframe"
      closeButtonLabel={closeButtonLabel?.value}
      onClose={handleDialogClose}
    >
      <div className="three-ds-iframe__container">
        <iframe
          data-testid="3ds-challenge-iframe"
          id="three-ds-iframe-redirect"
          title="PXP Payment Gateway"
          src={threeDsInfo?.redirectUrl}
          height="600px"
          width="100%"
          data-order-id={threeDsInfo?.orderId}
          data-pxp-id={threeDsInfo?.id}
          onLoad={onIframeReady}
          ref={threeDsIframe}
        />
      </div>
    </Dialog>
  );
}

ThreeDsChallengeForm.defaultProps = {
  threeDsMessage: {
    key: 'THREEDS_ANF_MESSAGING',
    value: 'Just one more step! For additional security, your bank will verify your payment using 3D Secure. Please contact your bank if you need assistance.',
  },
  closeButtonLabel: {
    key: 'GLB_CLOSE',
    value: 'Close',
  },
};

ThreeDsChallengeForm.propTypes = {
  threeDsInfo: PropTypes.shape({
    redirectUrl: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    paymentStatusURL: PropTypes.string.isRequired,
    orderId: PropTypes.string.isRequired,
  }).isRequired,
  threeDsMessage: PropTypes.shape({
    key: PropTypes.string,
    value: PropTypes.string,
  }),
  closeButtonLabel: PropTypes.shape({
    key: PropTypes.string,
    value: PropTypes.string,
  }),
  onSuccess: PropTypes.func.isRequired,
  onFailure: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};
