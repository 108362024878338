import {
  gql,
} from '@apollo/client';

// QUERY: gql query to fetch OrderConfirmationPage data
const ORDERCONFIRMATIONPAGE_DATA_QUERY = gql`
 query FetchOrderConfirmationPage {
    survey {
      surveyLinkUrl
    }
    brand
    userData {
      isLoggedIn
      hasLoyaltyEnabled
    }
    footer {
      orderId
      phoneNumberByCountry
      phoneNumberByLanguage
    }
    orderTotals {
      orderId
      subTotal
      totalItemsInBag
      cartTotalAmount
      cartTotalAmountFmt
      cartTotalAmountUSD
      cartPayableAmount
      subTotalFmt
      currency
      promoAmount
      promoAmountFmt
      shippingHandlingChargeFmt
      totalGiftBoxes
      totalGiftBoxesAmount
      displayFreeShippingText
      displayGiftReceiptText
      isGiftReceiptChecked
      estimatedTaxAmount
      estimatedTaxAmountFmt
      estimatedTaxAmountUSD
      charityRoundUpFmt
      includesConsumptionTax
      includesGst
      includesVat
      grandTotal
      grandTotalFmt
      charityTotalFmt
      subTotalUSD
      grandTotalUSD
      cartTotalAmountUSD
      totalAdjustmentUSD
      shippingCharge
      shippingChargeUSD
      shippingChargeFmt
    }
    rewardsAndPromotions {
      couponInfo {
        couponCode
      }
    }
    smsOptIn {
      smsIcon
      isEnabled
      isSelected
      privacyUrl
      textTermUrl
    }
    orderContact {
      email
      phoneNumber
    }
    promoInfo {
      promoCompId
      promotionCode
      promotionKey
      longDesc
      isShippingPromo
      isRewardPromo
      isPromoBadging
      isImplicitPromo
      amount
      amountFmt
      orderAdjustmentId
      shortDesc
      promotionType
    }
    bagItems {
      hasGiftCard
      hasOnlyGiftCard
      hasOnlyEGiftCard
      items {
       item {
         headers {
           badgeStatusMessage {
            key
            value
          }
           headerMessage
           estimatedShipDate
           shouldDisplayAsbadge
           attached
           fullWidth
           textAlign
           variant
         }
         footer {
           bogoMessage
         }
         image {
           altText
           imageSrc
           imageId
           classList
         }
         secondaryContent {
           buttonGroup
         }
         productContent {
           gender
           name
           size
           brand
           shortSku
           longSku
           orderItemId
           productId
           collectionId
           freeGift
           seq
           faceOut
           faceoutImage
           color
           notWrappable
           deliveryDate
           toEmail
           productPrice {
             description
             originalPrice
             discountPrice
             discountText
             variant
             priceFlag
             original
             discount
             originalUSD
             discountUSD
           }
           promotions {
             shortDesc
             promotionType
           }
           kicId
           isFinalSale
           itemShortDesc
           quantity
         }
       }
       giftBox {
         isWrapped
         giftBoxMessage
       }
      }
     }
     shippingSpeed {
      amount
      amountDisplay
      addressType
      country
      description
      estimatedDeliveryDate
      estimatedDeliveryDateDisplay
      externalId
      isSelected
      isValid
      isEnabled
      shipModeId
      shipModeType
      state
      availability {
        cartID
        courierEligible
        storeNo
        shipDate
        orderByTime
        timeZone
        reason
        hasOption
        postalCode
        isValid
        shouldBeSelected
        eligibilityConfirmed
        ineligibilityConfirmed
        usingExpressLanePayment
        displayChangeLink
        geolocation {
          country
          countryAbbr
          latlng
          postalCode
          postalTown
          state
          stateAbbr
        }
      }
    }
    shipMethod {
        shipModeType
        shipModeId
        estimatedDeliveryDateDisplay
        estimatedPreOrderShipDate
        amountDisplay
        description
    }
    shippingAddress {
      collectionPointID
      collectionPointProvider
      address1
      address2
      addressId
      country
      district
      displayType
      city
      firstName
      isBillingAllowed
      isDefaultBilling
      isDefaultShipping
      isShippingAllowed
      lastName
      orgName
      pickUpStoreId
      sameDayStoreId
      state
      title
      postalCode
      poBox
      isAddressVerified
    }
    giftCards {
        isAllowedGiftCards
        appliedGiftCards {
          id
          maskedNumber
          amountFmt
        }
    }
    orderInfo {
      paymentDetails {
        iconCode
        paymentLabel
        cardExpiryMonth
        cardExpiryYear
        cardType
        maskedCardNumber
        amountFmt
        payPalPaymentEmail
        isCreditCard
      }
      isCodPayment
    }
    pointsReward {
      displayPointsReward
      pointsRewardHeader { ...values }
      pointsRewardDesc { ...values }
      pointsRewardImage
      welcomeHeader { ...values }
      createAccountDesc { ...values }
      appDownloadDesc { ...values }
    }
    switches {
      loadCompleteMFEOrderConfirmationPage: isOn ( switchKey: "HasOrderConfirmationPageMFE")
    }
    config {
      isVatIncluded: bool (name: "isVatIncluded")
      showDisclosure: bool (name: "showDisclosure")
      isAMSite: bool (name: "isAMSite")
      isWDRegion: bool (name: "isWDRegion")
      isConsentOptional: bool (name: "isConsentOptional")
    }
    eSpots {
      checkoutShippingmessage: get(espotId: "checkout_shippingmessage") {...eSpotContentValues}
      ocnConfirmationPromotion: get(espotId: "ocn_confirmationpromo") {...eSpotContentValues}
    }
    signInUrl
    appDownload {
      androidDownloadLink
      iOsDownloadLink
      androidImageLink
      iOsImageLink
      seeMyAccountURL
      iOsDownloadLabel {
        value
        key
      }
      androidDownloadLabel {
        value
        key
      }
      seeMyAccountText {
        value
        key
      }
      checkoutAppDownloadHeading {
        value
        key
      }
    }
    textFor {
      surveyHeading: pair (pairKey: "surveyHeading") {...values},
      surveyDesc: pair (pairKey: "surveyDesc") {...values},
      surveyLinkLabel: pair (pairKey: "surveyLinkLabel") {...values}
      chatHelpTMNTValue: pair (pairKey: "chatHelpText") {...values}
      phoneNumberOrTMNTValue: pair (pairKey: "orText") {...values}
      customerServiceTMNTValue: pair (pairKey: "customerServiceText") {...values}
      orderNumberSymbolTMNTValue: pair (pairKey: "orderNoSymbolText") {...values}
      getTextUpdateTitle: pair (pairKey: "getTextUpdateTitle") {...values},
      getTextUpdateMessage: pair (pairKey: "getTextUpdateMessage") {...values},
      smsOptInSigned: pair (pairKey: "smsOptInSigned") {...values}
      privacyNotice: pair (pairKey: "privacyNotice") {...values}
      smsOptInAnd: pair (pairKey: "smsOptInAnd") {...values}
      textTerms: pair (pairKey: "textTerms") {...values}
      smsOptInDetail: pair (pairKey: "smsOptInDetail") {...values}
      yourOrder: pair (pairKey: "yourOrder") {...values}
      itemsLabel: pair(pairKey: "itemsLabel") {...values}
      pickupLocation: pair(pairKey: "pickupLocation") {...values}
      returnRefundButton: pair (pairKey: "returnRefundButton") {...values}
      exchangeButton: pair (pairKey: "exchangeButton") {...values}
      shippingHandlingTextTMNTValue: pair (pairKey: "shippingHandlingText") {...values}
      taxesTextTMNTValue: pair (pairKey: "taxesText") {...values}
      shipmentTotalTextTMNTValue: pair (pairKey: "shipmentTotalText") {...values}
      shippingAriaLabelTMNTValue: pair (pairKey: "shippingHandlingAriaLabel") {...values}
      freeShippingAmountTMNTValue: pair (pairKey: "freeShippingAmountText") {...values}
      freeShippingEarnedTMNTValue: pair (pairKey: "freeShippingEarnedText") {...values}
      giftBoxesLabelTMNTValue: pair (pairKey: "giftBoxesLabel") {...values}
      giftCardLabelTMNTValue: pair (pairKey: "giftCardLabel") {...values}
      charityLabelTMNTValue: pair (pairKey: "charityLabel") {...values}
      estimatedTaxLabelTMNTValue: pair (pairKey: "estimatedTaxLabel") {...values}
      orderTotal: pair (pairKey: "orderTotal") {...values}
      promoDiscountsLabel: pair (pairKey: "promoDiscountsLabel") {...values}
      popinsShippingAndHandling: pair (pairKey: "popinsShippingAndHandling") {...values}
      orderNumberSymbolTMNTValue: pair (pairKey: "orderNoSymbolText") {...values}
      orderPlacedTMNTValue: pair (pairKey: "orderPlaced") {...values}
      orderNumberTMNTValue: pair (pairKey: "orderNumber") {...values}
      confirmationSentToTMNTValue: pair (pairKey: "confirmationSentTo") {...values}
      estimatedDeliveryTMNTValue: pair (pairKey: "estimatedDelivery") {...values}
      reviewYourOrderTMNT: pair (pairKey: "reviewYourOrder") {...values}
      orderDetails: pair (pairKey: "orderDetails") {...values}
      popinsShippingAndHandling: pair (pairKey: "popinsShippingAndHandling") {...values}
      pickupPointShippingAndHandling: pair (pairKey: "pickupPointShippingAndHandling") {...values}
      freeShippingHandling: pair (pairKey: "freeShippingHandling") {...values}
      preOrderTMNT: pair (pairKey: "preOrder") {...values}
      inStockItemsOnlyTMNT: pair (pairKey: "inStock") {...values}
      cardNumberEndingTMNT: pair (pairKey: "cardNumberEndingText") {...values}
      expTextTMNT: pair (pairKey: "expText") {...values}
      expiresTextTMNT: pair (pairKey: "expiresText") {...values}
      shippingTextTMNT: pair (pairKey: "shippingText") {...values}
      yourPickupLocationTmnt: pair (pairKey: "checkYourPickUpText") {...values}
      estimatedPickupTmnt: pair (pairKey: "estimatedPickupText") {...values}
      storeHoursTmnt: pair (pairKey: "storeHoursText") {...values}
      altPickupOcnHeading: pair (pairKey: "altPickupText") {...values}
      altPickupOcnClock: pair (pairKey: "altPickupClockText") {...values}
      altPickupOcnEmail: pair (pairKey: "altPickupEmailText") {...values}
      altPickupOcnID: pair (pairKey: "altPickupIdText") {...values}
      sendTo: pair (pairKey: "sendTo") {...values}
      sendOn: pair (pairKey: "sendOn") {...values}
      vatTMNTValue: pair (pairKey: "vat") {...values}
      detailsTMNTValue: pair (pairKey: "details") {...values}
      welcomeToClubCali: pair(pairKey: "welcomeToClubCali") {...values}
      accountCreated: pair(pairKey: "accountCreated") {...values}
      youAreNowALoyaltyMemberPoints: pair(pairKey: "youAreNowALoyaltyMemberPoints") {...values}
      youAreNowALoyaltyMember: pair(pairKey: "youAreNowALoyaltyMember") {...values}
      earnPointsExplained: pair(pairKey: "earnPointsExplained") {...values}
      userName: pair(pairKey: "userName") {...values}
      userSignIn: pair(pairKey: "userSignIn") {...values}
      disclosureInfoTMNTValue: pair (pairKey: "disclosureInfo") {...values}
      disclosureTMNTValue: pair (pairKey: "disclosure") {...values}
      orderTotalIncludesConsumpTaxTMNTValue: pair (pairKey: "orderTotalIncludesConsumpTax") {...values}
      paymentLabel: pair(pairKey: "paymentLabel") {...values}
      trackYourOrder: pair(pairKey: "trackYourOrder") {...values}
      trackYourOrderExplained: pair(pairKey: "trackYourOrderExplained") {...values}
      dayOfDeliveryStep1: pair(pairKey: "dayOfDeliveryStep1") {...values}
      dayOfDeliveryStep2: pair(pairKey: "dayOfDeliveryStep2") {...values}
      dayOfDeliveryStep3: pair(pairKey: "dayOfDeliveryStep3") {...values}
      stepOne: pair(pairKey: "stepOne") {...values}
      stepTwo: pair(pairKey: "stepTwo") {...values}
      stepThree: pair(pairKey: "stepThree") {...values}
      returnsExplained: pair(pairKey: "returnsExplained") {...values}
      returns: pair(pairKey: "returns") {...values}
      passwordShow: pair(pairKey: "passwordShow") {...values}
      passwordHide: pair(pairKey: "passwordHide") {...values}
      password: textField(fieldKey: "password") {
        label {...values}
        error {...values}
      }
      emailLabel: textField(fieldKey: "email") {
        label {...values}
      }
      termsAndConditions: textField(fieldKey: "termsAndConditions") {
        label {...values}
        error {...values}
      }
      passwordRequirements: pair(pairKey: "passwordRequirements") {...values}
      buttonSubmit: pair(pairKey: "join") {...values}
      buttonProcessing: pair(pairKey: "processing") {...values}
      buttonError: pair(pairKey: "error") {...values}
      formError: pair(pairKey: "formError") {...values}
      giftReceipText: pair (pairKey: "giftReceipText") {...values}
      orderTotalIncludesGstTMNTValue: pair (pairKey: "orderTotalIncludesGst") {...values}
      orderTotalIncludesVatTMNTValue: pair (pairKey: "orderTotalIncludesVat") {...values}
      giftCardAmountUsed: pair (pairKey: "giftCardAmountUsed") {...values}
    }
  }
  fragment values on TextPair {
    key
    value
  }
  fragment eSpotContentValues on ESpotContent {
    name
    content
  }
`;

export const EMAIL_MARKETING_SUBSCRIBE = gql`
  mutation subscribe {
    subscribe {
      success
      statusCode
      statusMessages {
        code
        key
        message
      }
    }
  }
`;

export const CREATE_ACCOUNT_MUTATION = gql`
  mutation CreateAccount(
    $email: String!
    $password: String!
    $firstName: String!
    $lastName: String!
    $legalAccept: Boolean!
  ) {
    createAccount(
      email: $email,
      password: $password,
      firstName: $firstName,
      lastName: $lastName,
      legalAccept: $legalAccept
    ) {
      statusCode
      statusMessages {
        code
        key
        message
        params
      }
      success
    }
  }
`;

export default ORDERCONFIRMATIONPAGE_DATA_QUERY;
